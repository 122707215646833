import React from 'react';
import { motion } from "framer-motion";
import "./Backdrop.scss";

const Backdrop = ({ children, onClick, visible }) => {
    return (
        <motion.div
            onClick={onClick}
            className={ "backdrop" + (visible ? " visible" : "")  }
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            {children}
        </motion.div>
    );
};

export default Backdrop;
