import { motion } from "framer-motion";

export function AboutMePage() {
    return (
        <motion.div
            initial={{
                y: 50,
                opacity: 0,
            }}
            whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                    duration: 1,
                    type: "spring",
                    stiffness: 50
                }
            }}
            viewport={{
                once: true,
            }}
            className="page-content">
            <div className="page-name" data-title="About me">About me</div>
            <h1 className="page-title mb-3">A professional software engineer with a PhD in Electrical Engineering and Computing.</h1>
            <div className="page-text indented my-5">
                <p>Currently working in a biotech company as a Senior Back-End Software Developer.</p>
                <p>Skilled in JavaScript, TypeScript, Node.js, PHP, HTML, CSS, React, Databases (MySQL, MariaDB, MongoDB, Neo4j), CMS platforms such as WordPress (theme and plugin development), object-oriented programming (OOP), service-oriented programming, web programming, Android application development (Java and Kotlin), GNU/Linux administration, Google Cloud, AWS cloud solutions, etc. Mostly interested in all aspects of web development, both back-end and front-end.</p>
                <p>Since 2016, publishes free YouTube video lectures on various topics in computer programming (Java, Python, C), database design and modelling, operating systems, web development (HTML, CSS, JavaScript, TypeScript, PHP) etc. The YouTube channel is called <a href="https://www.youtube.com/c/MilanTair" target="_blank" rel="noreferrer">MilanTair</a>.</p>
                <p>
                    <a href="/cv/" className="button">Download my CV</a>
                </p>
            </div>
        </motion.div>
    );
}
