import './Counters.scss';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from 'react';

export interface ICounterDataItem {
    value: number;
    title: string;
}

interface ICounterProperties {
    counters: ICounterDataItem[];
}

function Counter(props: ICounterDataItem) {
    const [value, setValue] = useState<number>(0);
    const [ref, inView] = useInView();

    const step = Math.max(1, Math.floor(props.value / 1500));

    useEffect(() => {
        if (!inView) {
            return;
        }

        const timer = () => {
            setValue(Math.min(value + step, props.value));
        }

        if (value >= props.value) {
            return;
        }

        const id = setInterval(timer, Math.max(1, Math.min(5, Math.ceil(1000 / props.value))));

        return () => clearInterval(id);
    }, [ inView, value, props.value, step ]);

    return (
        <motion.div
            initial={{
                y: 50,
                opacity: 0,
            }}
            whileInView={{
                y: 0,
                opacity: 1,
            }}
            viewport={{ once: true }}
            ref={ ref } className="counter" key={ props.title }>
            <div className="counter-value">{ value }+</div>
            <div className="counter-name">{ props.title }</div>
        </motion.div>
    )
}

export function Counters(props: ICounterProperties) {
    return (
        <div className="counters mt-5">
            { props.counters.map(counter => <Counter key={ counter.title } title={ counter.title } value={ counter.value } />) }
        </div>
    );
}