export interface IProject {
    category: string;
    image: string;
    period: string;
    title: string;
    subtitle?: string;
    skills: string[];
    description: string;
    url?: string;
}

export const projects: IProject[] = [
	{
		category: "Applications",
		title: "MAGNA Platform",
		subtitle: "Depixus MAGNA One Web Platform",
		period: "Apr 2023 - Present",
		image: "/projects/biotech-platform.png",
		description: "Develpoment of the web platform, including databases, software infrastructure, development framework, web APIs, different micro-services and development tools for the Depixus MAGNA One Web Platform. The MAGNA platform handles data received from Depixus instruments, stores, processes it and generates reports. The platform is based on the micro-service architecture model and separates all of its logic into multiple individual domains.",
		skills: [ "Node.js", "MySQL", "MongoDB", "TypeScript", "APIs", "REST", "GitLab", "Redis", "RabbitMQ", "Nginx", "Mongoose", "Knex", "Express", "Visual Studio Code", "Git" ]
	},
	{
		category: "Applications",
		title: "PLUTUS",
		subtitle: "Singidunum University's System for Teacher Engagement Planning",
		period: "Jan 2020 - Oct 2020",
		image: "/projects/plutus.jpg",
		description: "An in-company software solution for teaching staff course assignments, semester planning, salary calculation and reporting. The PLUTUS application is built using the Nest framework, MongoDB database, HTML 5, Bootstrap CSS, JavaScript ad TypeScript. It was developed for Singidunum University for planning and tracking the engagement of the University's teaching staff. The application allows management to assign courses, link student groups on different faculties, study programmes, and lecture types, and assign multiple professors and assistants to those student groups. The application calculates pay due for these assigned engagements and provides an interface for teachers to review their assignments and verify their calculated pay on teaching obligations. The application was developed as a multi-level application, featuring an abstracted data mapping between the database and the back-end business logic at the API level, a front-end partially rendered server-side and partially client-side, for security reasons, as well as a separate application for teachers to view and verify the correctness of their registered engagements. The application is a basis for the creation of Microsoft Teams student groups and teams for online learning.",
		skills: [ "Node.js", "MongoDB", "NestJS", "TypeORM", "HTML", "CSS", "JavaScript", "jQuery", "APIs", "REST", "Visual Studio Code", "Git" ]
	},
	{
		category: "YouTube lessons",
		title: "YouTube video lectures",
		subtitle: "Creation of free educational IT video tutorials in Serbian",
		period: "May 2016 - Present",
		image: "/projects/yt-lectures-img.jpg",
		url: "https://youtube.com/@MilanTair",
		description: "Since 2016, I have been creating free educational video tutorials for people interested in learning programming (Java, Python, JavaScript/TypeScript), databases (MySQL/MariaDB etc.), operating systems (Ubuntu and CentOS GNU/Linux distros), web development (PHP, Node.js, HTML, CSS, JavaScript, React, Nest.js etc.), software testing (JUnit) etc. The lessons are taught in Serbian for people from Serbia who want to get into IT. All the videos are available in playlists on my YouTube channel.",
		skills: [ "Education", "Tutorials", "Video editing" ]
	},
	{
		category: "Applications",
		title: "MTutor feature development",
		subtitle: "Electronic Student Testing Platform",
		period: "Jun 2018 - Jul 2020",
		image: "/projects/mtutor.jpg",
		url: "https://test.singidunum.ac.rs/",
		description: "The UNIS MTutor is a web application developed for Singidunum University more than 15 years ago. It is a PHP application using MySQL RDBMS as its storage system. The application included a lot of legacy code and features that were becoming increasingly deprecated and incompatible with modern web browsers and platforms. The upgrade and feature development project were started in the summer of 2018 to bring the application up to the current standards and provide more time for the planned transition to a new application that would replace it. My task was to develop new features required by the teaching staff to implement new testing and assessment methods, provide new reporting projections, and allow the inclusion of different methods of creating questions for quizzes and tests used throughout the school year, either in class or in class or class for examination. Upon the outbreak of the Covid-19 global pandemic and the requirement to move classes online, additional features for student monitoring were required and implemented, such as using the camera to monitor student activities during the test, messaging, and live viewing during testing. The application was adapted and migrated to a newer PHP version and standard gradually for almost two years. The application was connected to the student information system to provide better control of student records and exam registrations in real-time. The technologies used in this project are PHP, MySQL, HTML, CSS, JavaScript, jQuery, the Smarty Templating engine, Apache, AJAX etc. There are four working copies of the application hosted locally at four locations of this Singidunum University in Serbia and one in Bosnia and Herzegovina, used by the Sinergija University. The creator of the original legacy application is Aleksandar Jevremović.",
		skills: [ "PHP", "MySQL", "Smarty", "HTML", "CSS", "JavaScript", "jQuery", "APIs", "REST", "Excel", "mPDF" ]
	},
	{
		category: "Applications",
		title: "SingiPay",
		subtitle: "Singidunum University's Online Credit Card Fee Payment System",
		period: "May 2017 - April 2023",
		image: "/projects/singipay.jpg",
		url: "https://singipay.singidunum.ac.rs/",
		description: "The SingiPay application was developed for Singidunum University to enable easier and quicker payment of different fees for exam registration and issuing certificates to Singidunum University's students. The application supports online payment via credit and debit cards. The application was developed as a PHP application using the bank's virtual POS terminal API in the background. Payment processing is done server-side, and reports are sent to different information systems for the student service and the accounting department. Administration and review are possible via a dashboard available from within the University's local network. Technologies, platforms and languages used include a relational database (MySQL), PHP 7, Apache2 web server, CSS Bootstrap, HTML5 and JavaScript.",
		skills: [ "PHP", "MariaDB", "PostgreSQL", "APIs", "REST", "Bank API", "HTML", "CSS", "JavaScript", "Git", "Excel", "mPDF", "Smarty", "PHP Mailer", "VPS", "GNU/Linux" ]
	},
	{
		category: "Applications",
		title: "Singipedia website",
		subtitle: "The Scientific Research Portal Singipedia (remade from scratch)",
		period: "Oct 2015 - Sep 2016",
		image: "/projects/singipedia.jpg",
		url: "https://singipedia.singidunum.ac.rs/",
		description: "Scientific Research Portal Singipedia was originally developed by Singidunum University years ago and was based on a Bulletin Board platform. The aim of the portal was and still is to help and support scientific research. Many scientific articles and other publications are published on the portal, including books, Master's and Doctoral theses, and articles from conferences. The new, remade version of the Singipedia Portal was built from scratch, from its database to the application framework and the design. It was developed to be faster, cleaner and slicker, and optimised as much as possible for search engines and other indexing engines. I have created the new application framework on which the portal is built and have made sure that every page is valid in terms of the HTML5 standard as much as possible. Pages use Schema.org metadata annotations for specific parts of page content, and the entire web interface was designed to be responsive to better adapt to mobile devices.",
		skills: [ "PHP", "MariaDB", "HTML", "CSS", "JavaScript", "jQuery", "Smarty", "Git", "mPDF", "Excel", "REST", "APIs", "VPS" ]
	},
	{
		category: "Websites",
		title: "Singidunum University websites",
		period: "Oct 2010 - May 2015",
		image: "/projects/singidunum.jpg",
		url: "https://singidunum.ac.rs/",
		description: "Singidunum University's website has been redesigned and a new CMS and framework have been developed for it and all of the integrated faculties' websites. The new website uses AJAX for page content acquisition from the back-end and is an improvement in terms of administration and amendment of website functionality. Singidunum UPIS website is a portal that provides all necessary information for future students such as online application submission forms, academic studies catalogue, study guides, and video clips of other students talking about studying at Singidunum University. The website uses an already established CMS and framework used on other Singidunum University’s websites.",
		skills: [ "PHP", "MySQL", "Smarty", "HTML", "CSS", "JavaScript", "jQuery", "APIs", "REST", "Git" ]
	},
	{
		category: "Mobile",
		title: "SingiDroid 3.0",
		subtitle: "The official Android application of Singidunum University - new version",
		period: "Nov 2014 - Dec 2014",
		image: "/projects/singidroid.jpg",
		url: "https://play.google.com/store/apps/details?id=univerzitet.singidunum.webdroid",
		description: "SingiDroid 3.0 is Singidunum University's official student information application for mobile phones with the Android operating system. It is intended for students of integrated faculties. SingiDroid started off as a class project of students from the Faculty of Informatics and Computing. Currently, its development and maintenance are done by Singidunum University's IT Centre. The main purpose of this application is to provide access to news from Singidunum University's website and websites of its integrated faculty websites. In addition to news reading, it provides help in finding the best route to Singidunum University's buildings. It shows the current month's Euro exchange rate, payment information for tuition fees, etc. The application allows users to look up the teacher consultations schedule (open hours), lecture schedules for all faculties, etc. Also, it allows for a direct connection with an independent application, \"Course pages\", which allows students to review notifications from their lectures' course pages. There is an option to directly contact Singidunum University's student administration department, as well as a list of answers to frequently asked questions.",
		skills: [ "PHP", "MySQL", "Java", "Android", "APIs", "REST", "Eclipse", "VPS", "GNU/Linux", "PHPUnit", "JUnit" ]
	},
	{
		category: "Applications",
		title: "The eMentor portal",
		subtitle: "The Official Student-Mentor Correspondence Platform",
		period: "May 2014 - Aug 2014",
		image: "/projects/ementor.jpg",
		url: "https://ementor.singidunum.ac.rs/",
		description: "The eMentor portal is an application developed for Singidunum University to facilitate easy communication between the student and their mentor during the preparation of the Master studies research project and writing of the final Master Thesis. The platform is an official and mandatory correspondence platform with all reviews of the master research project. The final thesis is kept and archived perpetually within this platform and is always available for review and inspection. The platform is a PHP application using a MySQL relational database. The application is divided into a student side application and the teacher side application. Teachers are tasked with marking a student as a mentored student, and they define the student's master studies research project topic and thesis topic. Students send their research drafts to their mentors, who conduct a review and write comments and suggestions for changes that the students are required to make. The process is defined by adopted regulations and policies and is automated. Technologies, platforms and languages used include a relational database (MySQL), PHP 7, Apache2 web server, HTML 5 and JavaScript.",
		skills: [ "PHP", "MySQL", "JavaScript", "Smarty", "APIs", "REST", "mPDF", "PHPUnit" ]
	},
	{
		category: "Websites",
		title: "KongresniTurizam website",
		subtitle: "Professional Conference Organiser Platform",
		period: "Feb 2014 - Aug 2014",
		image: "/projects/kongresniturizam.jpg",
		url: "https://web.archive.org/web/20141220125834/http://www.kongresniturizam.com/",
		description: "The \"Kongresni Turizam\" (Congress tourism) website was developed for The Best Solutions company from Belgrade, Serbia. It features rich and simple informative pages on congress and accommodation capacities of hotels, congress centres, event venues and agencies providing congress planning and organization services. The website includes a quick and detailed search of hotels, centres and venues by category, type, location and accommodation capacity, as well as a flight plan search which provides the user with a list of airline companies that fly on route to a selected country or directly from a selected country. There are also detailed and informative pages on countries and attractive city and town destinations in South-Eastern Europe.",
		skills: [ "PHP", "MySQL", "HTML", "CSS", "JavaScript", "jQuery", "VPS", "GNU/Linux", "Git" ]
	},
	{
		category: "Applications",
		title: "eAgenda portal - part of the EU Commission TEMPUS SIGMUS project",
		subtitle: "Part of the European TEMPUS Project",
		period: "Mar 2012 - May 2013",
		image: "/projects/eagenda.jpg",
		url: "https://web.archive.org/web/20121127072844/https://eagenda.singidunum.ac.rs/",
		description: "EU Project: eAgenda was a web portal created for the European Commission TEMPUS SIGMUS project conducted at Singidunum University that allows students to keep track of their daily activities and time spent on studying, projects, exams, etc. The resulting analysis of statistical information acquired from this portal will give more insight into how students spend their time, and what activities take the longest to complete and also allows faculty staff and management to take into account comments and remarks regarding how professors, assistants and student demonstrators organize class events.",
		skills: [ "PHP", "MySQL", "JavaScript", "Smarty", "Git", "APIs", "REST", "Excel" ]
	},
	{
		category: "Applications",
		title: "Vacation Schedule Planner",
		subtitle: "Singidunum University's employee vacation scheduler and planner",
		period: "Jun 2015 - Jun 2015",
		image: "/projects/odmori.jpg",
		description: "This application was developed as a PHP application with a MySQL relational database, running on an Apache2 web server, featuring a simple and intuitive user interface styled using CSS Bootstrap with many steps automated using JavaScript. Singidunum University's employees use the application, mostly the teaching staff, but it extends to administrative and support staff. The application allows employees to submit their plans and schedules for taking paid vacation days during the year within the available windows of opportunity outside of the school year and exam periods. The management can review and approve the submitted schedules, and the human resources administrative staff can generate and print official statements and documentation for each employee. The application keeps track of the remaining paid vacation days for each employee by their contract and position. Technologies, platforms and languages used include a relational database (MySQL), PHP 7, Apache2 web server, HTML 5, CSS Bootstrap and JavaScript.",
		skills: [ "PHP", "MySQL", "JavaScript", "PHPUnit", "Git" ]
	},
	{
		category: "Applications",
		title: "Employee management and timekeeping application",
		subtitle: "Singidunum University Employee Management Application",
		period: "Jan 2013 - Mar 2013",
		image: "/projects/employee-management.jpg",
		url: "https://checkin.singidunum.ac.rs/",
		description: "The application was developed for the management of all Singidunum University's employee information and work timekeeping. The application manages general information, contracts, work schedules, work timekeeping, reporting, vacation planning and management and all other jobs in the HR domain. The application was developed as a PHP application with the MySQL database, using the most current technologies of the time.",
		skills: [ "PHP", "MySQL", "HTML", "CSS", "JavaScript", "REST", "APIs", "AJAX", "Git" ]
	}
];
