import React from 'react';
import './Application.scss';
import { Navigation, NavigationItem } from '../Navigation/Navigation';
import { HomePage } from '../HomePage/HomePage';
import { Page } from '../Page/Page';

import { AboutMePage } from '../AboutMePage/AboutMePage';
import { Services } from '../Services/Services';
import { Projects } from '../Projects/Projects';

import { useScroll } from "framer-motion";
import { useEffect } from 'react';
import { projects } from '../Projects/projects.data';
import { recommendationData } from '../Recommendations/recommendation.data';
import { Recommendations } from '../Recommendations/Recommendations';
import { SocialItems } from '../SocialItems/SocialItems';

function Application() {
	const homePageId = 'home';
	const aboutPageId = 'about-me';
	const servicesPageId = 'services';
	const portfolioPageId = 'projects';
	const recommendationsPageId = 'recommendations';

	const navigationItems: NavigationItem[] = [
		{ title: 'Home', id: homePageId },
		{ title: 'About me', id: aboutPageId },
		{ title: 'What I do', id: servicesPageId },
		{ title: 'Portfolio', id: portfolioPageId },
		{ title: 'Testimonials', id: recommendationsPageId },
	];

	const { scrollYProgress } = useScroll();
	const [ hookedYPostion, setHookedYPosition ] = React.useState(0);
	const [ followerLogoY, setFollowerLogoY ] = React.useState(0);

	const followerLogoRef = React.createRef<HTMLDivElement>();

	useEffect(() => {
		scrollYProgress.onChange(v => setHookedYPosition(v));
	}, [ scrollYProgress ]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			const newY = window.pageYOffset + 160;
			setFollowerLogoY(newY);
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [ hookedYPostion ]);

	const year = +(new Date().getFullYear());

	return (
		<div className="Application">
			<div className="ruler-line left"></div>
			<div className="ruler-line right"></div>

			<Navigation items={ navigationItems } />
			<Page id={ homePageId }><HomePage /></Page>
			<Page id={ aboutPageId }><AboutMePage /></Page>
			<Page id={ servicesPageId }><Services /></Page>
			<Page id={ portfolioPageId }><Projects projects={ projects } /></Page>
			<Page id={ recommendationsPageId }><Recommendations recommendations={ recommendationData } /></Page>

			<div className="text-center my-4 medium">
			 	Copyright &copy; { (year < 2022) ? 2022 : year } <a href="https://milantair.com" target="_blank" rel="noreferrer">Milan Tair</a>.
				All rights reserved.
			</div>

			<div className="follower-logo" ref={ followerLogoRef } style={{ top: followerLogoY }}>
				<div className="follower-logo-note">
					Don't mind me.<br />
					I don't do anything.<br />
					I'm just keeping you company.
				</div>
			</div>

			<div className="fixed-sidemenu mobile-hide"><SocialItems /></div>
		</div>
	);
}

export default Application;
