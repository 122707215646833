import { motion } from "framer-motion";
import { IProject } from "./projects.data";
import './Projects.scss';
import React, { useState } from 'react';
import Modal from '../Modal/Modal';

interface IProjectSkillProperties {
    name: string;
}

function ProjectSkill(props: IProjectSkillProperties) {
    return (
        <div className="skill" itemProp="keywords">
            { props.name }
        </div>
    );
}

interface IProjectProperties {
    project: IProject,
    isHidden?: boolean;
}

function Project(props: IProjectProperties) {
    const [ expanded, setExpanded ] = useState<boolean>(false);

    const toggleExpandedState = React.useCallback(() => {
        setExpanded(!expanded);
    }, [ expanded, setExpanded ]);

    const ProjectView = () => {
        return (
            <motion.article
                initial={{
                    y: 30,
                    opacity: 1,
                }}
                className={ `project` + (props?.isHidden ? ' hidden' : '') + (expanded ? ' expanded' : '') }
                onClick={ () => toggleExpandedState() }
                itemScope itemType="https://schema.org/CreativeWork">

                <div className="project-image-wrapper">
                    <img className="project-image"
                        itemProp="image"
                        alt={ props.project.title }
                        src={ props.project.image } />
                </div>
                
                <div className="project-period" itemProp="dateCreated">{ props.project.period }</div>
                
                <div className="project-title" itemProp="name">{ props.project.title }</div>

                <div className="project-subtitle" itemProp="alternativeHeadline">{ props.project.subtitle ?? "⠀" }</div>

                { props.project?.skills.length > 0 && 
                    <div className="project-skills">
                        { props.project.skills.map(
                            skill => <ProjectSkill key={ props.project.title + "-" + skill } name={ skill } />
                        ) }
                    </div>
                }
                <button className="button">Full info</button>
            </motion.article>
        );
    }

    const ProjectViewForModal = () => {
        return (
            <motion.article
                initial={{
                    y: 30,
                    opacity: 1,
                }}
                className={ `project` + (props?.isHidden ? ' hidden' : '') + (expanded ? ' expanded' : '') }
                itemScope itemType="https://schema.org/CreativeWork">

                <div className="project-title" itemProp="name">{ props.project.title }</div>
                <div className="project-subtitle" itemProp="alternativeHeadline">{ props.project.subtitle ?? "⠀" }</div>
                <div className="project-period" itemProp="dateCreated">{ props.project.period }</div>

                { props.project?.skills.length > 0 && 
                    <div className="project-skills">
                        { props.project.skills.map(
                            skill => <ProjectSkill key={ props.project.title + "-" + skill } name={ skill } />
                        ) }
                    </div>
                }

                <motion.div
                    transition={{
                        type: "linear",
                        default: {
                            east: "linear",
                        }
                    }}
                    className="project-description" itemProp="abstract">
                    { props.project.description }
                </motion.div>
                
                { props.project?.url &&
                    <motion.a
                        initial={{
                            top: 10,
                        }}
                        animate={{
                            top: 0
                        }}
                        className="project-url"
                        target="_blank"
                        rel="noreferrer"
                        href={ props.project.url }>
                        <span>View project</span>
                        <meta itemProp="image" content={ props.project.url } />
                    </motion.a>
                }
            </motion.article>
        );
    }

    return (
        <>
            <ProjectView />
            <Modal handleClose={() => setExpanded(() => false) } visible={ expanded }>
                <ProjectViewForModal />
            </Modal>
        </>
    );
}

interface IProjectsProperties {
    projects: IProject[];
}

export function Projects(props: IProjectsProperties) {
    const [ selectedCategory, setSelectedCategory ] = useState<string>("All projects");

    const uniqueCategories = [
        "All projects",
        ...props.projects.map(project => project.category).filter((value, index, self) => self.indexOf(value) === index).sort()
    ];

    return (
        <div className="page-content">
            <div className="page-name" data-title="Portfolio">Portfolio of selected works</div>
            <div className="category-filter mt-3 d-none">
                { uniqueCategories.map(category => (
                    <span className={ `category-filter-item` + (selectedCategory === category ? ' active' : '') }
                        key={ "category-filter-" + category }
                        onClick={ () => setSelectedCategory(category) }>
                        <span>{ category }</span>
                    </span>
                )) }
            </div>
            <div className="projects mt-3">
                { props.projects
                    .map(project => (
                        <Project key={ project.title + "-" + project.subtitle + "-" + project.image }
                            project={ project }
                            isHidden={ !(selectedCategory === "All projects" || project.category === selectedCategory) } />
                    ) ) }
            </div>
        </div>
    );
}
