import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './common/reportWebVitals';
import Application from './components/Application/Application';

import './common/styles/index.scss';
import './common/styles/mobile.scss';

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

root.render(
	<React.StrictMode>
		<Application />
	</React.StrictMode>
);

reportWebVitals();
