import './Page.scss';
import { motion } from "framer-motion";

export interface PageProperties extends React.PropsWithChildren {
    id: string;
}

export function Page(props: PageProperties) {
    return (
        <motion.section
            initial={{
                y: 50,
                opacity: 0,
            }}
            whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                    duration: 1,
                    type: "spring",
                    stiffness: 50
                }
            }}
            viewport={{
                once: true,
            }}
            className="page" id={ props.id }>
            { props.children }
        </motion.section>
    );
}
