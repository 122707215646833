import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { IRecommendation } from './recommendation.data';
import './Recommendations.scss';
import React, { useCallback, useState, useEffect } from 'react';

interface IRecommendationsProperties {
    recommendations: IRecommendation[];
}

export function Recommendations(props: IRecommendationsProperties) {
    const [ current, setCurrent ] = useState<number>(0);

    const sliderRef = React.createRef<HTMLDivElement>();
    const arrowLeftRef = React.createRef<HTMLDivElement>();
    const arrowRightRef = React.createRef<HTMLDivElement>();

    const slide = useCallback((step: number) => {
        if (current + step < 0) {
            return;
        }

        if (current + step >= props.recommendations.length) {
            return;
        }

        sliderRef.current?.scrollTo({ behavior: 'smooth', left: (sliderRef.current?.querySelector('.recommendation-slider-item:nth-child(' + (current + step + 1) + ')') as HTMLElement)?.offsetLeft });

        setCurrent(current + step);
    }, [current, props.recommendations.length, sliderRef]);

    useEffect(() => {
        arrowLeftRef.current?.style.setProperty("opacity", current === 0 ? "0" : "1");
        arrowRightRef.current?.style.setProperty("opacity", current === props.recommendations.length - 1 ? "0" : "1");
    }, [current, arrowLeftRef, arrowRightRef, props.recommendations.length]);

    useEffect(() => {
        arrowLeftRef.current?.style.setProperty("opacity", current === 0 ? "0" : "1");
    }, [arrowLeftRef, current]);

    return (
        <motion.div
            initial={{
                y: 50,
                opacity: 0,
            }}
            whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                    duration: 1,
                    type: "spring",
                    stiffness: 50
                }
            }}
            viewport={{
                once: true,
            }}
            className="page-content">
            <div className="page-name" data-title="Testimonials">Testimonials</div>
            <div className="recommendation-slider-holder my-3">
                <div className="recommendation-slider-arrow left" onClick={ () => slide(-1) } ref={ arrowLeftRef }>
                    <FontAwesomeIcon icon={ faArrowLeftLong } />
                </div>
                <div className="recommendation-slider-arrow right" onClick={ () => slide(+1) } ref={ arrowRightRef }>
                    <FontAwesomeIcon icon={ faArrowRightLong } />
                </div>
                <div className="recommendation-slider-wrapper" ref={ sliderRef }>
                    <div className="recommendation-slider">
                        { props.recommendations.map(recommendation => (
                            <div className="recommendation-slider-item"
                                 key={ "recommendation-" + recommendation.name }
                                 itemScope itemType="https://schema.org/EndorsementRating">
                                <div className="recommendation-slider-item-text" itemProp="ratingExplanation"
                                    dangerouslySetInnerHTML={{ __html: recommendation.text }}></div>
                                <div className="recommendation-slider-item-person" itemProp="author" itemScope itemType="https://schema.org/Person">
                                    <meta itemProp="url" content={ recommendation.url }></meta>
                                    <meta itemProp="name" content={ recommendation.name }></meta>
                                    <a className="recommendation-slider-item-person-photo-holder" href={ recommendation.url } rel="noreferrer" target="_blank">
                                        <img itemProp="image" src={ recommendation.photo } alt={ recommendation.name } />
                                    </a>
                                    <a className="recommendation-slider-item-name" href={ recommendation.url } rel="noreferrer" target="_blank">{ recommendation.name }</a>
                                    <div className="recommendation-slider-item-title" itemProp="jobTitle">{ recommendation.title }</div>
                                    <div className="recommendation-slider-item-company" itemProp="affiliation" itemScope itemType="https://schema.org/Organization"><span itemProp="name">{ recommendation.company }</span></div>
                                    <div className="recommendation-slider-item-date">{ recommendation.date }</div>
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
                <div className="recommendation-slider-dots mt-4">
                    { props.recommendations.map((recommendation, index) => (
                        <span key={ "recommendation-dot-" + recommendation.name }
                            className={ `recommendation-slider-dot` + ((index === current) ? ' active' : '') }
                            data-step={ index - current }
                            onClick={ () => slide(index - current) }></span>
                    )) }
                </div>
            </div>
        </motion.div>
    );
}
