import './Services.scss';

import { motion } from "framer-motion"

import systemEngineering from "./system-engineering.png";
import webDev from "./web-dev.png";
import database from "./database.png";
import universityTeaching from "./university.png";
import videoTutorial from "./video-tutorial.png";
import android from "./android.png";
import ai from "./ml-ai.png";
import lecturing from "./lecture.png";
import { Counters, ICounterDataItem } from '../Counters/Counters';

interface IService {
    icon: string,
    title: string,
    text: string
}

export function Services() {
    const services: IService[] = [
        {
            icon: systemEngineering,
            title: "System Engineering",
            text: "I can design and implement every part of a completely customised solution based on the company’s requirements to provide a complete digital transformation.",
        },
        {
            icon: webDev,
            title: "Web Development",
            text: "With almost a decade and a half of working in PHP, JavaScript/TypeScript, I can create any web application (both back-end and front-end) in nearly every framework."
        },
        {
            icon: database,
            title: "Database Design",
            text: "Analysing, modelling, and implementing databases of various sizes, complexities, and types (relational, document-oriented and graph) is my everyday routine."
        },
        {
            icon: universityTeaching,
            title: "University Teaching",
            text: "I have worked in higher education since 2012 as a teaching assistant and an assistant professor. I have over 4000 hours in class and have taught over 1000 students."
        },
        {
            icon: videoTutorial,
            title: "Video Tutorials",
            text: "I have created over 500 free educational tutorial videos in Serbian, published on YouTube so that everyone can get into IT more easily."
        },
        {
            icon: android,
            title: "Android Development",
            text: "I have developed several Android apps and worked as an Android developer in the early years of Android development (2010-2014)."
        },
        {
            icon: ai,
            title: "ML/AI Consulting",
            text: "A significant part of my doctoral studies scientific research concerns application of ML and AI. Need help applying them in your business?"
        },
        {
            icon: lecturing,
            title: "Guest Lecturing",
            text: "As an experienced educator with professional training also outside of academia, I can hold tailored training and lectures at request."
        },
    ];

    const counters: ICounterDataItem[] = [
        { title: "Completed projects", value: 75, },
        { title: "Students taught", value: 1000, },
        { title: "Mentorships", value: 10, },
        { title: "YouTube lessons", value: 500, },
        { title: "Lecture hours", value: 4000, },
    ];

    return (
        <div className="page-content">
            <div className="page-name" data-title="What I do">What I do</div>
            <div className="service-block-holder mt-3">
                { services.map(service => (
                    <motion.div
                        initial={{
                            y: 50,
                            opacity: 0,
                        }}
                        whileInView={{
                            y: 0,
                            opacity: 1,
                        }}
                        viewport={{ once: true }}
                        whileHover={{
                            scale: 1.2,
                        }}
                        className="service-block" key={ service.title }
                        itemScope itemType="https://schema.org/Service">
                        <img className="service-block-image" src={ service.icon } alt={ service.title } />
                        <h2 className="service-block-title" itemProp="name">{ service.title }</h2>
                        <div className="service-block-text" itemProp="slogan">{ service.text }</div>
                    </motion.div>
                )) }
            </div>

            <Counters counters={ counters } />
        </div>
    );
}
