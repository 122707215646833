export function HomePage() {
    return (
        <div className="page-content" itemScope itemType="https://schema.org/Person">
            <div className="page-name">Hi, my name is</div>
            <h1 className="page-title" itemProp="name">Milan Tair</h1>
            <h2 className="page-subtitle" itemProp="jobTitle">Senior Back-End Software Developer</h2>
            <div className="page-text my-3" itemProp="description">A lifetime programmer who loves software development.</div>
            <a href="mailto:milan.tair@gmail.com" className="button">Contact me</a>
            <meta itemProp="email" content="milan.tair@gmail.com" />
        </div>
    );
}
