import "./Navigation.scss";
import milantexLogo from "../../common/images/milantex-logo.svg";
import { SocialItems } from "../SocialItems/SocialItems";
import { useState } from "react";

export interface NavigationItem {
    title: string;
    id: string,
}

export interface NavigationProperties {
    items: NavigationItem[];
}

export function Navigation(props: NavigationProperties) {
    const [ isOpen, setOpen ] = useState<boolean>(false);

    const scrollToPage = (id: string) => {
        document.getElementById(id)?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });

        setOpen(false);
    };
 
    return (
        <nav className={ "navigation" + (isOpen ? " open" : "") }>
            <img className="mobile-show logo-mobile" src={ milantexLogo } alt="Milan Tair's logo" onClick={ () => scrollToPage('home') } />
            <div className="menu-hamburger mobile-show" onClick={ () => setOpen(!isOpen) }>
            	<div className="menu-hamburger-items">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <ul  className="navigation-items">
                <li className="navigation-item logo mobile-hide">
                    <img src={ milantexLogo } alt="Milan Tair's logo" onClick={ () => scrollToPage('home') } />
                </li>
                { props.items.map(item => (
                    <li className="navigation-item text" key={ item.title } onClick={ () => scrollToPage(item.id) }>
                        { item.title }
                    </li>
                )) }
                <li className="navigation-item social mobile-show">
                    <SocialItems />
                </li>
            </ul>
        </nav>
    );
}
