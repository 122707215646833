import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons';

interface ISocialItemLink extends React.PropsWithChildren {
    href: string;
    title: string;
}

function SocialItemLink(props: ISocialItemLink) {
    return (
        <a href={ props.href } target="_blank" rel="noreferrer" title={ props.title }>
            { props.children }
        </a>
    );
}

export function SocialItems() {
	return (
		<>
            <SocialItemLink href="mailto:milan.tair@gmail.com" title="Click here to send me an email.">
				<FontAwesomeIcon icon={ faEnvelope } />
            </SocialItemLink>

			<SocialItemLink href="https://linkedin.com/in/milantex" title="Click here to connect with me via LinkedIn.">
				<FontAwesomeIcon icon={ faLinkedinIn } />
			</SocialItemLink>

			<SocialItemLink href="https://github.com/Milantex" title="Click here to see my GitHub profile.">
				<FontAwesomeIcon icon={ faGithub } />
			</SocialItemLink>

			<SocialItemLink href="https://youtube.com/c/MilanTair" title="Click here to see my YouTube channel.">
				<FontAwesomeIcon icon={ faYoutube } />
			</SocialItemLink>

			<SocialItemLink href="https://scholar.google.com/citations?user=qcnZBkYAAAAJ" title="Click here to see my Google Scholar profile.">
				<svg version="1.1" viewBox="0 0 122.88 122.88" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-.000436522 -2.302)" fill="#fff"><path d="m59.4766 5.13672-57.4766 45.5039 25.8789 21.0723c6.61407-10.9943 18.1839-18.6497 31.6191-19.918-0.0038-0.07134-0.02148-0.138618-0.02148-0.210938zm-27.2051 70.1543 31.168 25.3789v-25.3789z"/><path d="m63.4043 5.13867v46.4453c0 0.07232-0.0177 0.139594-0.02148 0.210938 13.4349 1.2688 25.0055 8.92375 31.6191 19.918l25.8789-21.0723zm-3.96484 70.1523v25.3789l31.168-25.3789z"/><path d="m28.3398 73.291c-1.76171 4.23488-2.74024 8.8762-2.74024 13.748 0 19.79 16.0498 35.8418 35.8398 35.8418s35.8398-16.0518 35.8398-35.8418c0-4.87184-0.976574-9.51317-2.73828-13.748z"/></g></svg>
			</SocialItemLink>
		</>
	);
}
