import React from 'react';
import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import "./Modal.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';

const dropIn = {
    hidden: {
        y: "-25vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.2,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};

const Modal = ({ children, handleClose, visible }) => {
    return (
        <Backdrop onClick={handleClose} visible={visible}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal orange-gradient"
                variants={dropIn}
                initial="hidden"
                animate={
                    visible ? "visible" : "hidden"
                }
                exit="exit">
                <button onClick={handleClose} className="modal-close-button">
                    <FontAwesomeIcon icon={ faRemove } />
                </button>
                { children }
            </motion.div>
        </Backdrop>
    );
};

export default Modal;
